'use strict';

// check for Numaric value
export const isNumber = function (value) {
    var number = value;
    var myRegEx = /^(\s*[0-9]+\s*)+$/;
    var isValid = myRegEx.test(number);
    if (isValid) {
        return true;
    } else {
        return false;
    }
}

/**
* @desc Check empty String
* @return Boolean
*
*/
export const isEmpty = function (val) {
    if (val === undefined || val === '' || val === null) {
        return true;
    } else {
        return false;
    }
}


export const isName = function (val) {
    var name = val;
    var myRegEx = /[a-zA-Z][a-zA-Z ]{2,}$/;
    var isValid = myRegEx.test(name);
    if (isValid) {
        return true;
    } else {
        return false;
    }
}

/**
* @desc Check Valid Email
* @return Boolean
*
*/
export const isValidEmail = function (val) {
    var email = val;
    var myRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = myRegEx.test(email);
    if (isValid) {
        return true;
    } else {
        return false;
    }
}

/**
* @desc Check Valid Mobile
* @return Boolean
*
*/
export const isValidMobile = function (val) {
    var number = val;
    var myRegEx = /^(\s*[0-9]+\s*)+$/;
    var isValid = myRegEx.test(number);
    if (isValid) {
        return true;
    } else {
        return false;
    }
}
//   

/**
* @desc Check Valid Mobile Country Code
* @return Boolean
*
*/
export const isValidMobileCode = function (val) {
    var number = val;
    var myRegEx = /^(\+?\d{1,3}|\d{1,4})$/;
    var isValid = myRegEx.test(number);
    if (isValid) {
        return true;
    } else {
        return false;
    }
}

/**
* @desc html entity code
* @return Boolean
*
*/
export const removeHtmlEntity = function (html) {
    var el = document.createElement('div');
    el.innerHTML = html;
    return el.childNodes[0];
}

/**
* @desc Check Valid Url
* @return Boolean
*
*/
export const isValidUrl = function (str) {
    var regexp = new RegExp("\\b(https?|ftp|file)://[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]");
    if (!regexp.test(str)) {
        return false;
    } else {
        return true;
    }
}
export const isValidEventUrl = function (str) {
    var regexp = new RegExp(/(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm);
    if (!regexp.test(str)) {
        return false;
    } else {
        return true;
    }
}

/**
* @desc Password Validation
* @return Boolean
*
*/
export const validPassword = function (str) {
    var regexp = /(?=.*[A-Z])/;
    if (!regexp.test(str)) {
        return false;
    } else {
        return true;
    }
}

export const currentTime = function (date_time) {
    var utc_time = new Date(date_time);
    var millis = utc_time.getTime() - (utc_time.getTimezoneOffset() * 60000)
    var local_time = new Date(millis);
    return new Date(local_time);
}

export const isSedoDomain = function (domain) {
    switch (domain.toLowerCase()) {
        case 'footage.com':
        case 'medicating.com':
        case 'bidding.com':
        case 'kart.com':
        case 'educating.com':
        case 'researching.com':
        case 'correcting.com':
        case 'reforming.com':
        case 'experimenting.com':
            return true;
        default:
            return false;
    }
}