import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { BrandService } from '../../services/brand.service';

// import * as $ from 'jquery'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public ActivecatgeoryList = [];
  public BrandFilterList: any = [];
  timer
  public search_keyword: any;
  public sort_by: any;
  public category: any;
  public search_type: any;
  public min_price: any = 0;
  public max_price: any = 0;
  public min_length: any = 0;
  public max_length: any = 0;
  public brand_type: any = 'all';
  public filterSortBy: any;
  public observer:any;
  public brandcount = 0;
  public brandsearchtext;
  public pageSizes = 0;
  pageOfItems: Array<any>;
  public filter_class: any = false;
  @ViewChild('scrollBottom', { static: false }) scrollBottom: ElementRef;
  constructor(public brandService: BrandService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.search_keyword = params['keyword'];
        this.searchapply();
      }
    )
  }

  ngOnInit() {
    this.brandService.getActiveCategoryList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        this.ActivecatgeoryList = res.data;

      }
    });
    $('.seraech-field .form-group .buttonlinks a').on('click', function () {
      $(this).addClass('active').parent().siblings().children().removeClass('active');
    });
    $("header .search-grp a").click(function () {
      $(".seraech-field").addClass("open-filer");
    });
    $(".seraech-field .form-group a.closebtn ").click(function () {
      $(".seraech-field").removeClass("open-filer");
    });

  }


  onChangePage(pageOfItems: Array<any>) {
    var scrollingElement = document.scrollingElement || document.documentElement;
    scrollingElement.scrollTop = 2;
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  clearall() {

    this.search_keyword = '';
    this.sort_by = '';
    this.category = '';
    this.search_type = '';
    this.min_price = 0;
    this.max_price = 0;
    this.min_length = 0;
    this.max_length = 0;
    this.searchapply()
  }

  cancel() {
    this.search_keyword = '';
    this.sort_by = '';
    this.category = '';
    this.search_type = '';
    this.min_price = 0;
    this.max_price = 0;
    this.min_length = 0;
    this.max_length = 0;
    this.searchapply()
  }

  openHide() {
    this.filter_class = !this.filter_class;
  }

  searchapply() {
    $(".seraech-field").removeClass("open-filer");
    this.brandsearchtext = this.search_keyword

    var obj = {
      search_keyword: this.search_keyword,
      sort_by: this.sort_by,
      category: this.category,
      search_type: this.search_type,
      min_price: this.min_price,
      max_price: this.max_price,
      min_length: this.min_length,
      max_length: this.max_length,
      brand_type: this.brand_type
    }
    this.brandService.searchApply(obj).subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        if (res.data) {
          if (this.sort_by) {
            if (this.sort_by == 'price_low_to_high') {
              this.filterSortBy = "Price Low to High"
            } else if (this.sort_by == 'price_high_to_low') {
              this.filterSortBy = "Price High to Low"
            } else if (this.sort_by == 'first_letter') {
              this.filterSortBy = "First Letter Alphabetically"
            } else if (this.sort_by == 'date_added') {
              this.filterSortBy = "Date Added"
            } else if (this.sort_by == 'relevance') {
              this.filterSortBy = "Relevance"
            } else {
              this.filterSortBy = this.sort_by
            }

          }
          this.BrandFilterList = [];
          // this.timer = setTimeout(() => {}, 4000);




          this.BrandFilterList = res.data.sort((a, b) => a.display_order - b.display_order || b.price - a.price);
          // get xbot.com, vap.com, idle.com and each.com and put them 29th, 30th, 31st and 32nd position
          let xbot = this.BrandFilterList.filter(x => x.domain.toLowerCase() === 'xbot.com');
          let vap = this.BrandFilterList.filter(x => x.domain.toLowerCase() === 'vap.com');
          let idle = this.BrandFilterList.filter(x => x.domain.toLowerCase() === 'idle.com');
          let each = this.BrandFilterList.filter(x => x.domain.toLowerCase() === 'each.com');

          // remove xbot.com, vap.com, idle.com and each.com from the list
          this.BrandFilterList = this.BrandFilterList.filter(x => x.domain != 'xbot.com' && x.domain != 'vap.com' && x.domain != 'idle.com' && x.domain != 'each.com');


          if (xbot.length > 0) {
            console.log('xbot', xbot);
            this.BrandFilterList = [
              ...this.BrandFilterList.slice(0, 28),
              ...xbot,
              ...this.BrandFilterList.slice(28)
            ]
          }

         
          if (vap) {
            this.BrandFilterList = this.BrandFilterList.filter(x => x.domain != 'vap.com');
            this.BrandFilterList = [
              ...this.BrandFilterList.slice(0, 29),
              ...vap,
              ...this.BrandFilterList.slice(29)
            ]
          }

          
          if (idle) {
            this.BrandFilterList = this.BrandFilterList.filter(x => x.domain != 'idle.com');
            this.BrandFilterList = [
              ...this.BrandFilterList.slice(0, 30),
              ...idle,
              ...this.BrandFilterList.slice(30)
            ]
          }

         
          if (each) {
            this.BrandFilterList = this.BrandFilterList.filter(x => x.domain != 'each.com');
            this.BrandFilterList = [
              ...this.BrandFilterList.slice(0, 31),
              ...each,
              ...this.BrandFilterList.slice(31)
            ]
          }

          
          this.brandcount = res.data.length;

          // create the observer
          const options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0,
          };
          this.observer = new IntersectionObserver((entries, observer) => {
            this.pageSizes += 15;
            this.pageOfItems = this.BrandFilterList.slice(0, this.pageSizes);
          }, options);
          // console.log('this.scrollBottom', this.scrollBottom);
          // observe the last li element
          this.observer.observe(this.scrollBottom.nativeElement);
          

        }
      } else {
        this.filterSortBy = '';
        this.BrandFilterList = [];
        this.brandcount = res.data.length;
      }
    });
  }

}
