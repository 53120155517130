<app-header></app-header>

<main>
  <section class="sign-up-section" style="border-top: none;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <form novalidate="" class="sign-up-form login-width ">
            <div class="sign-up-form-head">
              <h3>{{ title }}</h3>
            </div>

            <div class="sign-up-form-body">
              <div class="row" *ngIf="type!='purchase'">
                <div class="col-md-12">
                  <div class="comments"
                    style="border: 1px solid #ccc; padding: 10px; border-radius: 5px;background-color: #eee;">
                    <p style="font-weight: bolder;">For this domain we are expecting a sales price of at least {{
                      brand.price | currency}} <br />
                      Or lease to own from {{ brand.price / 96 | currency}} per month. <br />
                      You can also rent the domain from {{ brand.price / 240 | currency}} per month.</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line"><input type="text" placeholder="Your Name *" name="name" id="name" required
                        [(ngModel)]="name" class="form-control ">
                      <small class="error" *ngIf="validationErrors.name">{{
                        validationErrors.name}}</small>
                    </div>

                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line"><input type="email" [(ngModel)]="email" placeholder="Email *" name="email"
                        id="email" required class="form-control">
                      <small class="error" *ngIf="validationErrors.email">{{
                        validationErrors.email}}</small>
                    </div>

                  </div>
                </div>
                <div class="col-md-12" *ngIf="type!=='purchase'">
                  <div class="input-group blmd-form">
                    <div class="blmd-line"><textarea rows="6" [(ngModel)]="social_links"
                        placeholder="Enter your social media links, one per line (optional)" height="150" name="message"
                        class="form-control"></textarea>
                      <small class="error" *ngIf="validationErrors.social_links">{{
                        validationErrors.social_links}}</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line"><input type="text" [(ngModel)]="domain" disabled name="domain" id="domain"
                        required class="form-control">
                      <small class="error" *ngIf="validationErrors.domain">{{
                        validationErrors.domain}}</small>
                    </div>

                  </div>
                </div>

                <div class="col-md-12" *ngIf="!hidePrice">
                  <div class="input-group blmd-form">
                    <div class="blmd-line"><input type="number" [(ngModel)]="price" name="price" id="price" required
                        [disabled]="!priceEnabled" class="form-control" min="100" step="1" placeholder="How much you would like to offer?">
                      <small class="error" *ngIf="validationErrors.price">{{
                        validationErrors.price}}</small>
                    </div>

                  </div>
                </div>

                <div class="col-md-12" *ngIf="type!=='purchase'">
                  <div class="input-group blmd-form">
                    <div class="blmd-line">
                      <label>Who are you? *</label>
                      <small class="error" style="position: relative; display: block; width: 100%; bottom: 0;"
                        *ngIf="validationErrors.who_are_you">{{
                        validationErrors.who_are_you}}</small>
                      <div *ngFor="let who of who_are_you_options">
                        <label style="font-weight: normal;">
                          <input type="radio" [(ngModel)]="who_are_you" name="who_are_you" [value]="who.value"
                            required>
                          &nbsp;{{who.label}}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" *ngIf="type!=='purchase'">
                  <div class="input-group blmd-form">
                    <div class="blmd-line">
                      <label>What is the purpose of your inquiry? *</label>
                      <small class="error" style="position: relative; display: block; width: 100%; bottom: 0;"
                        *ngIf="validationErrors.inquiry_purpose">{{
                        validationErrors.inquiry_purpose}}</small>
                      <div *ngFor="let purpose of inquiry_purpose_options">
                        <label style="font-weight: normal;">
                        <input type="radio" [(ngModel)]="inquiry_purpose" name="inquiry_purpose" [value]="purpose.value"
                          required>
                        &nbsp;{{purpose.label}}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line"><textarea rows="6" [(ngModel)]="message" required
                        placeholder="Tell us about why you're interested in this name. Please include as many details as possible so we can vet your inquiry. *"
                        height="150" name="message" class="form-control"></textarea>
                      <small class="error" *ngIf="validationErrors.message">{{
                        validationErrors.message}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="customLoader" style="display: none;"><img src="assets/images/loader.svg" alt=""
                      style="width: 40px;"></div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button (click)="sendForm()" class="btnsignup">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>